import { useEffect, useState } from 'react';
import { TAsset, TMarketAsset } from '../../../types/types';
import { AssetIcon, FavouriteIcon, PercentChangeIcon } from '../icons';

interface WalletItemProps {
	asset: TAsset;
	idx: number;
}

const WalletItem = ({ asset, idx }: WalletItemProps) => {
	return (
		<div key={idx} className='assetItem'>
			<AssetIcon
				className='assetIcon'
				group={asset?.group}
				assetName={asset?.name}
			/>
			<div className='innerAssetItemHeader'>
				<h1 className='innerAssetHeaderText'>{asset?.name}</h1>
				<h1 className='innerAssetBottomText'>{asset?.fullName}</h1>
			</div>
			{asset?.amountUSD ? (
				<div className='innerAssetItemAmount'>
					<h1 className='innerAssetItemAmountText'>
						{asset?.amount?.toLocaleString()}
					</h1>
					<h1 className='innerAssetItemAmountUSDText'>
						{asset?.amountUSD?.toLocaleString()}
					</h1>
				</div>
			) : (
				<div className='innerAssetItemAmount'>
					<h1 className='innerAssetItemAmountText'>
						{asset?.amount?.toLocaleString()}
					</h1>
				</div>
			)}
		</div>
	);
};

interface MarketAssetItemProps {
	asset: TMarketAsset;
	idx: number;
}

const MarketAssetItem = ({ asset, idx }: MarketAssetItemProps) => {
	const [isFavourite, setIsFavourite] = useState<boolean>(false);

	const [isPositivePercent, setIsPositivePercent] = useState<boolean>(false);

	useEffect(() => {
		setIsPositivePercent(asset.percentChange > 0);
	}, []);

	return (
		<div key={idx} className='marketAssetItem'>
			<FavouriteIcon
				className=''
				isActive={isFavourite}
				onClick={() => setIsFavourite(!isFavourite)}
			/>
			<AssetIcon
				className='assetIcon'
				group={asset.group}
				assetName={asset.name}
			/>
			<div className='marketAssetItemNameContainer'>
				<h1 className='upperMarketItemText'>{asset.name}</h1>
				<h1 className='bottomMarketItemText'>{asset.fullName}</h1>
			</div>
			<div className='marketAssetItemRateContainer'>
				<h1 className='upperMarketItemText'>
					{asset.rate.toLocaleString()} USDT
				</h1>
				<div className='rateChevContainer'>
					<PercentChangeIcon
						isPositive={isPositivePercent}
						className=''
					/>
					<h1
						className={
							isPositivePercent
								? 'positivePercent'
								: 'negativePercent'
						}
					>
						{asset.percentChange
							.toLocaleString()
							.replace('-', '')}
					</h1>
				</div>
			</div>
		</div>
	);
};

export { WalletItem, MarketAssetItem };
