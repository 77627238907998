export const ROUTES = {
	WALLET: {
		INDEX: "/:locale/wallet"
	},
	EXCHANGE: {
		INDEX: "/:locale/exchange"
	},
	MARKET: {
		INDEX: "/:locale/market"
	},
	ROOT: {
		INDEX: "/"
	},
	INACTIVE: {
		INDEX: "/:locale/inactive"
	}
};
