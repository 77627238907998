import { useTranslation } from 'react-i18next';
import {
	BottomNavigationExchangeIcon,
	BottomNavigationMarketIcon,
	BottomNavigationWalletIcon,
	CalendarIcon,
	DepositIcon,
	ExchangeIcon,
	MarketIcon,
	WithdrawIcon
} from '../icons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { useLocation } from 'react-router-dom';
import { TAssetsTab } from '../../../types/types';

const BottomNavigationContainer = () => {
	const location = useLocation();
	const navigate = useCustomNavigate();

	const mapIsActive = (iconName: string): boolean => {
		if (location?.pathname?.split('/')[2]?.toLowerCase() === iconName) {
			return true;
		}
		return false;
	};

	const handleNavigate = (route: string) => {
		navigate(route);
	};

	return (
		<div className='bottomNavigationContainer'>
			<div className='bottomNavigationDivider' />
			<div className='innerBottomNavigationContainer'>
				<BottomNavigationMarketIcon
					className={''}
					onClick={() => handleNavigate(ROUTES.MARKET.INDEX)}
					isActive={mapIsActive('market')}
				/>
				<BottomNavigationExchangeIcon
					className={''}
					onClick={() => handleNavigate(ROUTES.EXCHANGE.INDEX)}
					isActive={mapIsActive('exchange')}
				/>
				<BottomNavigationWalletIcon
					className={''}
					onClick={() => handleNavigate(ROUTES.WALLET.INDEX)}
					isActive={mapIsActive('wallet')}
				/>
			</div>
		</div>
	);
};

const assetsNavigationItems = ['All', 'Favourites', 'Hot', 'New', 'ScrollTes1', 'ScrollTes2'];

interface AssetsNavigationContainerProps {
	currentTab: TAssetsTab;
	handleTabChange: (tab: TAssetsTab) => void;
}

const AssetsNavigationContainer = ({
	currentTab,
	handleTabChange
}: AssetsNavigationContainerProps) => {

	const {t} = useTranslation();

	const mapIsActiveTab = (tab: TAssetsTab) => {
		if (tab.toLowerCase() === currentTab.toLowerCase()) {
			return true;
		}
		return false;
	};

	return (
		<div className='assetsNavigationContainer'>
			<div className='scrolledAssetsNavigation'>
				{assetsNavigationItems.map((asset, idx) => (
					<div
						key={idx}
						onClick={() => handleTabChange(asset as TAssetsTab)}
						className={
							'assetNavigationItem' +
							(mapIsActiveTab(asset as TAssetsTab)
								? ' assetNavigationItemActive'
								: '')
						}
					>
						<h1 className='assetNavigationItemText'>{t(asset.toLowerCase())}</h1>
					</div>
				))}
			</div>
			<div className='assetsDateFilterContainer'>
				<div className='assetsDateFilter'>
					<CalendarIcon />
					<h1 className='assetsDateFilterText'>24h</h1>
				</div>
			</div>
		</div>
	);
};

const MainNavigationContainer = () => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	return (
		<div className='mainNavigationContainer'>
			<div className='navigationItem'>
				<DepositIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('deposit')}</h1>
			</div>
			<div
				onClick={() => navigate(ROUTES.EXCHANGE.INDEX)}
				className='navigationItem'
			>
				<ExchangeIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('exchange')}</h1>
			</div>
			<div className='navigationItem'>
				<WithdrawIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('withdraw')}</h1>
			</div>
			<div
				onClick={() => navigate(ROUTES.MARKET.INDEX)}
				className='navigationItem'
			>
				<MarketIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('market')}</h1>
			</div>
		</div>
	);
};

export {
	MainNavigationContainer,
	BottomNavigationContainer,
	AssetsNavigationContainer
};
