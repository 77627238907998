import { useTranslation } from 'react-i18next';
import {
	CurrencyChev,
	EllipseCheckBoxIcon,
	F2XMainIcon,
	HiddenBalanceIcon,
	PositiveChev,
	SearchIcon,
} from '../common/icons';
import { MainNavigationContainer } from '../common/navigation';
import { ROUTES } from '../../types/routes';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useState } from 'react';
import { WalletItemList } from '../common/lists';
import { TAsset } from '../../types/types';

const WalletLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [isBalanceHidden, setIsBalanceHidden] = useState<boolean>(false);
	const [isLowBalanceHidden, setIsLowBalanceHidden] = useState<boolean>(false);

	//test array of assets
	const assetsArray: TAsset[] = [
		{
			name: 'USD',
			fullName: 'United States Dollar',
			group: 'Fiat',
			amount: 199.0,
		},
		{
			name: 'BYN',
			fullName: 'Belarussian ruble',
			group: 'Fiat',
			amount: 10000.02534,
			amountUSD: 9999.59,
		},
		{
			name: 'BTC',
			fullName: 'Bitcoin',
			group: 'Crypto',
			amount: 0.541200123,
			amountUSD: 12914.5,
		},
		{
			name: 'USD',
			fullName: 'United States Dollar',
			group: 'Fiat',
			amount: 15000.043,
		},
		{
			name: 'USDT',
			fullName: 'United States Dollar Tokenized',
			group: 'Crypto',
			amount: 10000.1,
			amountUSD: 10681.59,
		},
		{
			name: 'BTC',
			fullName: 'Bitcoin',
			group: 'Crypto',
			amount: 1.2350001,
			amountUSD: 12914.5,
		},
		{
			name: 'USD',
			fullName: 'United States Dollar',
			group: 'Fiat',
			amount: 1234.403011,
		},
		{
			name: 'EUR',
			fullName: 'Euro',
			group: 'Fiat',
			amount: 10000.0,
			amountUSD: 1061.59,
		},
		{
			name: 'BTC',
			fullName: 'Bitcoin',
			group: 'Crypto',
			amount: 0.5,
			amountUSD: 1,
		},
		{
			name: 'USD',
			fullName: 'United States Dollar',
			group: 'Fiat',
			amount: 5,
		},
		{
			name: 'EUR',
			fullName: 'Euro',
			group: 'Fiat',
			amount: 10000.0,
			amountUSD: 89,
		},
		{
			name: 'BTC',
			fullName: 'Bitcoin',
			group: 'Crypto',
			amount: 0.5,
			amountUSD: 0.23,
		},
	];

	const num = 54087.92;

	return (
		<div className="walletLayout">
			<div
				onClick={() => setIsBalanceHidden(!isBalanceHidden)}
				className="estimatedBalanceContainer"
			>
				<h1 className="estimatedBalanceText">{t('estimatedBalanceText')}</h1>
				<HiddenBalanceIcon
					isHidden={isBalanceHidden}
					className="estimatedBalanceIcon"
				/>
			</div>

			<div className="balanceContainer">
				<div className="mainBalanceContainer">
					<h1 className="mainBalanceText">
						{isBalanceHidden ? '***,**' : num.toLocaleString()}
					</h1>
					<div className="mainBalanceSelectContainer">
						<h1 className="currencyText">USD</h1>
						<CurrencyChev className="currencyTextChev" />
					</div>
				</div>
				<div className="secondaryBalanceContainer">
					<h1 className="secondaryBalanceText">{t('todayText')}</h1>
					<div className="secondaryBalanceAnalyticsContainer">
						<PositiveChev className="analyticsChev" />
						<h1 className="secondaryBalanceAnalyticsText">
							15,12% (+534,11 USD)
						</h1>
					</div>
				</div>
			</div>

			<MainNavigationContainer />

			<F2XMainIcon />

			<div className="hideBalanceContainer">
				<div
					onClick={() => setIsLowBalanceHidden(!isLowBalanceHidden)}
					className="innerHideBalanceContainer"
				>
					<EllipseCheckBoxIcon
						className="hideLowBalanceIcon"
						isActive={isLowBalanceHidden}
					/>
					<p className="hideBalanceText">
						{t('hideBalanceText')}
						<span
							onClick={() => navigate(ROUTES.EXCHANGE.INDEX)}
							className="hideBalanceLinkText"
						>
							{t('convertText')}
						</span>
					</p>
				</div>
				<SearchIcon className="searchIconContainer" />
			</div>

			<WalletItemList
				isLowBalanceHidden={isLowBalanceHidden}
				currencies={assetsArray}
			/>
		</div>
	);
};

export default WalletLayout;
