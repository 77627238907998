import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import ExchangeLayout from '../components/exchange';

const Exchange = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.EXCHANGE.INDEX}</title>
			</Helmet>
			<ExchangeLayout />
		</>
	);
};

export default Exchange;
