import { useMemo } from 'react';
import { MarketAssetItem, WalletItem } from '../items';
import { TAsset, TMarketAsset } from '../../../types/types';

interface WalletItemListProps {
	currencies: TAsset[];
	isLowBalanceHidden: boolean;
}

const WalletItemList = ({
	currencies,
	isLowBalanceHidden
}: WalletItemListProps) => {
	//TEST VALUE
	const minHideValue = 1000;

	function filterCurrenciesByMinHideValue(
		currencies: TAsset[],
		isLowBalanceHidden: boolean
	) {
		if (isLowBalanceHidden) {
			return currencies.filter((currency) =>
				currency?.amountUSD
					? currency.amountUSD > minHideValue
					: currency.amount > minHideValue
			);
		} else {
			return currencies;
		}
	}

	const visibleCurrencies = useMemo(
		() => filterCurrenciesByMinHideValue(currencies, isLowBalanceHidden),
		[currencies, isLowBalanceHidden]
	);

	return (
		<div className='assetsContainer'>
			{visibleCurrencies.map((currency, idx: number) => (
				<WalletItem key={idx} idx={idx} asset={currency} />
			))}
		</div>
	);
};

interface MarketAssetsList {
	assets: TMarketAsset[];
}

const MarketAssetsList = ({ assets }: MarketAssetsList) => {
	return (
		<div className='marketAssetsContainer'>
			{assets.map((asset, idx: number) => (
				<MarketAssetItem key={idx} idx={idx} asset={asset} />
			))}
		</div>
	);
};

export { WalletItemList, MarketAssetsList };
